import * as React from "react"
import { useState } from 'react';
import EventLayout from "../components/event-layout";
import Seo from "../components/seo";
import {CalendarIcon, LocationMarkerIcon} from "@heroicons/react/outline";
import Moment from "moment";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer' 
import {evitatCommunityId} from "../services/constant";
import Link from "gatsby-link";
import momentTimezone from "moment-timezone";
import { graphql, useStaticQuery } from "gatsby"
import { data } from "autoprefixer";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import parse from "html-react-parser"
import { getUser } from "../services/auth";

export const DisplayEvents = () => {
    const tabViewEvent = (e, event) => {
        if(event == 'current') {
            setViewEvent('current');
        } else {
            setViewEvent('past');
        }
    }
    const [viewEvent, setViewEvent] = useState('current');
    const eventsData = useStaticQuery(graphql`
    # Write your query or mutation here
    query{
        allContentfulEvitatCommunityEvent{
          edges{
            node{
              communityId
              eventSlug
              title{
                title
              }
              subtitle{
                subtitle
              }
              description{
                raw
              }
              startTime
              endTime
              location
              geoLocation{
                lon
                lat
              }
              eventLocationAddress{
                eventLocationAddress
              }
              eventOnlineLocation{
                eventOnlineLocation
              }
              panelistsView
              
              panelists{
                references{
                  name
                  jobTitle
                  jobDescription{
                    jobDescription
                  }
                  profileImage{
                    file{
                      url
                    }
                  }
                }
              }
              pastEvent
              backgroundImage{
                file{
                  url
                }
              }
            } 
          }
        }
      }`)
    
      const ascEventsData = eventsData.allContentfulEvitatCommunityEvent.edges.sort((event_1, event_2) => { return new Date(event_1.node.startTime) - new Date(event_2.node.startTime) } );
    var showdown  = require('showdown');
    var mdToHtml = new showdown.Converter();
    const numberOfUpcomingEvents = eventsData.allContentfulEvitatCommunityEvent.edges.filter(edge => edge.node.pastEvent === false);
    return (
        <EventLayout imageUrl="url('//images.ctfassets.net/ql4sip4kv9bc/6Vah4GtACCU2ZdHd4BhMJk/c57be718232a65066ea319282980d49b/event-header-image.png')">
            <Seo title="Our events"/>
            
            <div className="overflow-hidden lg:relative bg-transparent py-12 py-20">
                <div className="mx-auto mt-20 max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="max-w-5xl mx-auto">
                        <h1 className="mt-1 mb-2 font-extrabold text-white text-3xl md:text-5xl  sm:tracking-tight lg:text-5xl">Join
                            us in our live-events!</h1>
                    </div>
                </div>
            </div>
            <div className="overflow-hidden lg:relative bg-transparent bg-gray-50 mt-32">
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="max-w-6xl mx-auto">
                        <div className="position-relative">
                            <p className="text-xl"><span className={`cursor-pointer ${viewEvent == 'current' ? "text-gray-900 font-semibold" : "text-gray-400"}`} onClick={e => tabViewEvent(e, 'current')}>Upcoming</span> <span className={`ml-12 cursor-pointer ${viewEvent == 'past' ? "text-gray-900 font-semibold" : "text-gray-400"}`} onClick={e =>tabViewEvent(e, 'past')}>Past events</span></p>
                        </div>
                    </div>
                </div>
            </div>
            {viewEvent == 'current' &&
            <div className="overflow-hidden lg:relative bg-transparent bg-gray-50">
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="max-w-6xl mx-auto">
                        <div className="bg-gray-50">
                        { numberOfUpcomingEvents.length === 0 && 
                            <div class="max-w-5xl"><h1 class="mt-1 mb-2 font-extrabold text-green-500 sm:tracking-tight lg:text-2xl">There are no upcoming events at the moment! Susbscribe to our newsletter below to get latest updates</h1></div>
                        }                        
                        {/* { numberOfUpcomingEvents.length === 0 && getUser().email != undefined && */}
                        
                        {/* { numberOfUpcomingEvents.length === 0 && getUser().email == undefined &&
                        <div><div class="max-w-5xl"><h1 class="mt-1 mb-2 font-extrabold text-green-500 sm:tracking-tight lg:text-2xl">There are no upcoming events at the moment!</h1></div>
                        <div className="flex">
                            <button className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">Sign up</button> 
                            <span>with Evitat today to receive latest updates!</span></div>
                        </div>} */}
                        {ascEventsData?.map((item) => (
            (item.node.pastEvent === false && item.node.location != null) &&
            <div key={item.node.communityId}
                 className="max-w-6xl mt-8 mb-12 mx-auto bg-white rounded-tl-lg rounded-tr-lg">
                <div className="bg-white rounded-lg shadow-xl">
                    <div className="md:ml-8 ml-4 mt-4 mr-4 md:mr-8 inline-block">
                        <h1 className="mt-1 mb-2 font-extrabold text-gray-700 text-2xl sm:tracking-tight">{item.node.title === null ? "NULL": parse((mdToHtml.makeHtml(item.node.title.title)).replaceAll(/<p>|<\/p>/gi,""))}</h1>
                        <p className="mt-2 text-xl text-gray-900 max-w-full">{item.node.subtitle === null ? "NULL": parse((mdToHtml.makeHtml(item.node.subtitle.subtitle)).replaceAll(/<p>|<\/p>/gi,""))}</p>
                    </div>
                    <div
                        className="grid md:grid-cols-12 sm:grid-cols-1 md:gap-y-20 lg:grid-cols-12 lg:gap-y-0 lg:gap-x-8">
                        <div className="md:col-span-4">
                            <div className="md:ml-8 ml-4 mt-2 mb-8">
                                <div className="float-left">
                                    <LocationMarkerIcon strokeWidth="1"
                                                        className="flex-shrink-0 w-9 h-9 mr-1.5 text-gray-700"
                                                        aria-hidden="true"/>
                                </div>
                                
                                <div className="text-gray-700 ml-12"><span
                                    className="text-2xl font-semibold  block">
                                    
                                    <span
                                    className="text-green-500">FREE</span> {(item.node.location === true) ? 'Online' : 'In-person'}</span>
                                    {(item.node.location === true) &&
                                    <span className="text-xl block">{parse((mdToHtml.makeHtml(item.node.eventOnlineLocation.eventOnlineLocation)).replaceAll(/<p>|<\/p>/gi,""))}</span>}
                                    {item.node.location === false &&
                                    <span
                                        className="text-xl block">{parse((mdToHtml.makeHtml(item.node.eventLocationAddress.eventLocationAddress)).replaceAll(/<p>|<\/p>/gi,""))}</span>}

                                </div>
                                
                            </div>
                        </div>
                        <div className="md:col-span-4">
                            <div className="md:mt-2 mb-8 md:ml-8 ml-4">
                                <div className="float-left">
                                    <CalendarIcon strokeWidth="1"
                                                  className="flex-shrink-0 w-9 h-9 mr-1.5 text-gray-700"
                                                  aria-hidden="true"/>
                                </div>
                                <div className="text-gray-700 ml-12">
                                    <span
                                        className="text-2xl font-semibold block">{Moment(item.node.startTime).format('ddd Do MMM YYYY')},</span>
                                    <span
                                        className="text-xl  block">{Moment(item.node.startTime).format('hh:mm a')} - {Moment(item.node.endTime).format('hh:mm a')} {momentTimezone.tz([Moment(item.node.startTime).tz('UTC').format('YYYY'),Moment(item.node.startTime).tz('UTC').format('MM')], Moment.tz.guess()).zoneAbbr()} </span>

                                </div>
                            </div>
                        </div>
                        <div className="md:col-span-4">
                            <div
                                className="md:mt-4 ml-4 md:ml-8 md:ml-0 mr-4 md:mr-8 mb-8 md:mb-0">
                                <Link to={`/events/${item.node.eventSlug}`}
                                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-xl font-medium text-indigo-500 hover:text-indigo-500 bg-green-200 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-300">Learn
                                    more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
                        </div>
                    </div>
                </div>
            </div>
            }
            {viewEvent == 'past' &&
            <div className="overflow-hidden lg:relative bg-transparent bg-gray-50">
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="max-w-6xl mx-auto">
                        <div className="bg-gray-50">
                        {ascEventsData.reverse()?.map((item) => (
            (item.node.pastEvent === true && item.node.location != null) &&
            <div key={item.node.communityId}
                 className="max-w-6xl mt-8 mb-12 mx-auto bg-white rounded-tl-lg rounded-tr-lg">
                <div className="bg-white rounded-lg shadow-xl">
                    <div className="md:ml-8 ml-4 mt-4 mr-4 md:mr-8 inline-block">
                        <h1 className="mt-1 mb-2 font-extrabold text-gray-700 text-2xl sm:tracking-tight">{item.node.title === null ? "NULL": parse((mdToHtml.makeHtml(item.node.title.title)).replaceAll(/<p>|<\/p>/gi,""))}</h1>
                        <p className="mt-2 text-xl text-gray-900 max-w-full">{item.node.subtitle === null ? "NULL": parse((mdToHtml.makeHtml(item.node.subtitle.subtitle)).replaceAll(/<p>|<\/p>/gi,""))}</p>
                    </div>
                    <div
                        className="grid md:grid-cols-12 sm:grid-cols-1 md:gap-y-20 lg:grid-cols-12 lg:gap-y-0 lg:gap-x-8">
                        <div className="md:col-span-4">
                            <div className="md:ml-8 ml-4 mt-2 mb-8">
                                <div className="float-left">
                                    <LocationMarkerIcon strokeWidth="1"
                                                        className="flex-shrink-0 w-9 h-9 mr-1.5 text-gray-700"
                                                        aria-hidden="true"/>
                                </div>
                                
                                <div className="text-gray-700 ml-12"><span
                                    className="text-2xl font-semibold  block">
                                    
                                    <span
                                    className="text-green-500">FREE</span> {(item.node.location === true) ? 'Online' : 'In-person'}</span>
                                    {(item.node.location === true) &&
                                    <span className="text-xl block">{parse((mdToHtml.makeHtml(item.node.eventOnlineLocation.eventOnlineLocation)).replaceAll(/<p>|<\/p>/gi,""))}</span>}
                                    {item.node.location === false &&
                                    <span
                                        className="text-xl block">{parse((mdToHtml.makeHtml(item.node.eventLocationAddress.eventLocationAddress)).replaceAll(/<p>|<\/p>/gi,""))}</span>}

                                </div>
                                
                            </div>
                        </div>
                        <div className="md:col-span-4">
                            <div className="md:mt-2 mb-8 md:ml-8 ml-4">
                                <div className="float-left">
                                    <CalendarIcon strokeWidth="1"
                                                  className="flex-shrink-0 w-9 h-9 mr-1.5 text-gray-700"
                                                  aria-hidden="true"/>
                                </div>
                                <div className="text-gray-700 ml-12">
                                    <span
                                        className="text-2xl font-semibold block">{Moment(item.node.startTime).format('ddd Do MMM YYYY')},</span>
                                    <span
                                        className="text-xl  block">{Moment(item.node.startTime).format('hh:mm a')} - {Moment(item.node.endTime).format('hh:mm a')} {momentTimezone.tz([Moment(item.node.startTime).tz('UTC').format('YYYY'),Moment(item.node.startTime).tz('UTC').format('MM')], Moment.tz.guess()).zoneAbbr()} </span>

                                </div>
                            </div>
                        </div>
                        <div className="md:col-span-4">
                            <div
                                className="md:mt-4 ml-4 md:ml-8 md:ml-0 mr-4 md:mr-8 mb-8 md:mb-0">
                                <Link to={`/events/${item.node.eventSlug}`}
                                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-xl font-medium text-indigo-500 hover:text-indigo-500 bg-green-200 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-300">Learn
                                    more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
                        </div>
                    </div>
                </div>
            </div>
            }
        </EventLayout>
    )
    
  }

  export default DisplayEvents;